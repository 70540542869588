import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import InfoCard from '../../components/InfoCard';
import { AboutSidebarLinks } from '../../helpers/constants';
import EDGI from "../../images/EDGI-logo.webp";
import Packard from "../../images/funders-packard.png";
import ESIP from "../../images/funders-esip.png";
import CSS from "../../images/funders-css.png";
import NEU from "../../images/funders-neu.png";
import SBU from "../../images/funders-sbu.png";
import DDCF from "../../images/funders-ddcf.png";
import EDGIperson from "../../images/funders-EDGIperson.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="Funding" sidebarLinks={AboutSidebarLinks} activeHeaderLink="About" mdxType="Layout">
      <h1>{`Donors & Fundraising`}</h1>
      <p>{`Thank you to the generous donors who make this work possible!`}</p>
      <div className='row'>
    <InfoCard title="Volunteers" description='Many EDGI contributors are skilled volunteers who donate their labor.' link='https://www.envirodatagov.org' image={EDGI} mdxType="InfoCard" />
    <InfoCard title="Packard Foundation" description='Sustaining organizational funding.' link='https://www.packard.org' image={Packard} mdxType="InfoCard" />
    <InfoCard title="ESIP Lab" description='UX testing and website rebuild, 2021.' link='https://www.esipfed.org/lab' image={ESIP} mdxType="InfoCard" />
    <InfoCard title="Code for Science & Society Virtual Events Fund" description='Funding for highly accessible virtual events, made possible by grant number GBMF8449 from the Gordon and Betty Moore Foundation (https://doi.org/10.37807/GBMF8449).' link='https://www.eventfund.codeforscience.org' image={CSS} mdxType="InfoCard" />
    <InfoCard title="Northeastern University Lab for Texts, Maps, and Networks" description='Helped to fund the 2020 Intern program.' link='https://web.northeastern.edu/nulab' image={NEU} mdxType="InfoCard" />
    <InfoCard title="Stony Brook University" description='In-kind donation to mirror the databases core to this work.' link='https://it.stonybrook.edu/services/teaching-learning-lab' image={SBU} mdxType="InfoCard" />
    <InfoCard title="Doris Duke Charitable Foundation" description='Sustaining organizational funding.' link='https://ddcf.org' image={DDCF} mdxType="InfoCard" />
    <InfoCard title="Individual Donors Like You" description='Small donations go a long way at EDGI. Thank you for your support!' link='https://envirodatagov.org/donate/' image={EDGIperson} mdxType="InfoCard" />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      